import React,{useEffect,useState,useRef} from 'react'
import loading from '../../logo.jpg'
import "./explore.css"
import axios from 'axios';
import {MapContainer,TileLayer,Popup,ScaleControl,useMap,useMapEvent,Marker,Rectangle} from 'react-leaflet';
import "leaflet/dist/leaflet.css"
import 'leaflet-geosearch/dist/geosearch.css';
import 'leaflet.locatecontrol'
import 'leaflet.locatecontrol/dist/L.Control.Locate.min.css'
import 'leaflet.markercluster';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import L from 'leaflet';
import {GeoSearchControl, OpenStreetMapProvider} from 'leaflet-geosearch';

const Explore = ({serverURL,utente}) => {
  document.title="Explore | Journey"

  const [citta,setCitta]=useState()
  const [coords,setCoords]=useState()
  const [view,setView]=useState()
  const [result,setResult]=useState()
  const [info,setInfo]=useState()
  const [caricamento,setCaricamento]=useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState('it')
  async function findAttractionBounds(latNe,lonNe,latSw,lonSw){
    axios.get(`https://nominatim.openstreetmap.org/reverse?lat=${(latNe+latSw)/2}&lon=${(lonNe+lonSw)/2}&format=json`).then(e=>setCitta(e.data)).catch(err=>alert("Error: "+err))
    setCaricamento(true)
    await axios.put(serverURL+"wikiBound",{
      latNe:latNe,lonNe:lonNe,latSw:latSw,lonSw:lonSw,
      raggio:"area",
    },{headers:{'Content-Type':'multipart/form-data'}}).then((e)=>{
      setCaricamento(false)
      if(e.status===200){
        if(e.data.length>0){
          setResult(e.data)
        }else{
          setResult()
        }
      }else{
        alert("Error: "+e.data)
      }
    }).catch(err=>alert("Error: "+err))
  }
  //ottieni i testi per ogni attrazione
  const wikiText=async(nome,wikipedia,wikidata,lat,lon,id,img)=>{
    if(lat&&lon){
      if(!localStorage.getItem("cronology")){
        localStorage.setItem("cronology",JSON.stringify([{nome:nome,lat:lat,lon:lon,img:img,data:new Date()}]))
      }else{
        const cronology=JSON.parse(localStorage.getItem("cronology"))
        if(!cronology.find(e=>e.nome===nome)){
          if(cronology.length>=10)cronology.shift()
          cronology.push({nome:nome,lat:lat,lon:lon,img:img,data:new Date()})
          localStorage.setItem("cronology",JSON.stringify(cronology))
        }
      }
    }
    setInfo()
    setCaricamento(true)
    axios.put(serverURL+"wikiText",{
      nome:nome,
      wikipedia:wikipedia,
      wikidata:wikidata,
      lat:lat,
      lon:lon,
      lingua:document.getElementById("language").value,
    },{headers:{'Content-Type':'multipart/form-data'}}).then((e)=>{
      setCaricamento(false)
      if(e.status===200){
        axios.put(serverURL+"getSubmitToIdWiki",{idWiki:id},{headers:{'Content-Type':'multipart/form-data'}}).then(j=>{
          if(j.status===200){
            setInfo({id:id,nome:nome,info:e.data,submitted:j.data});
          }else{
            setInfo({id:id,nome:nome,info:e.data});
          }
        })
        document.getElementById("resizableDiv-info").style.transform="translateY(0%)"
        document.getElementById("resizableDiv-info").style.opacity="1"
        if(document.getElementById("resizableDiv-info").style.transform="translateY(95%)"){
          document.getElementById("resizableDiv-info").style.transform="translateY(0%)"
        }
      }else{
        alert("Error: "+e.data)
      }
    })
  }
  //leggi il testo
  async function leggiTesto(testo,titolo,x2,y2){
    if(utente){
      setCaricamento(true)
      const audio=document.getElementById("audio")
      audio.scrollIntoView({behavior:"smooth"})
      audio.src=null
      audio.load()
      let distanza
      if(x2&&y2){
        distanza=calcolaDistanza(coords[0],coords[1],x2,y2)
        if(titolo==="In generale"){
          testo="Puoi vederlo alla distanza di "+(distanza).toFixed(1)+". "+testo
        }else if(titolo==="In general"){
          testo="You can see it at the distance of "+(distanza).toFixed(1)+". "+testo
        }
      }
      axios.put(serverURL+"wikiAudio",{
        testo:testo,
        titolo:titolo,
        lingua:document.getElementById("language").value,
        id:utente.nome
      },{headers:{'Content-Type':'multipart/form-data'}}).then((e)=>{
        setCaricamento(false)
        if(e.status===200){
          setTimeout(()=>{
            audio.src=(serverURL+e.data)
            audio.type='audio/mpeg'
            audio.load()
          },500)
        }else{
          alert("Error: "+e.data)
        }
      })
    }else{
      alert("Audio Error: To obtain this attraction point audio you must be registered -> /login")
    }
  }
  //vedi il testo
  /*async function vediTesto(testo,titolo,imgs,x2,y2){
    if(utente){
      if(window.confirm("The waiting time is very long, are you sure you want to continue?")){
        setCaricamento(true)
        const audio=document.getElementById("audio")
        audio.scrollIntoView({behavior:"smooth"})
        audio.src=null
        audio.load()
        let distanza
        if(x2&&y2){
          distanza=calcolaDistanza(coords[0],coords[1],x2,y2)
          if(titolo==="In generale"){
            testo="Puoi vederlo alla distanza di "+(distanza).toFixed(1)+". "+testo
          }else if(titolo==="In general"){
            testo="You can see it at the distance of "+(distanza).toFixed(1)+". "+testo
          }
        }
        axios.put(serverURL+"wikiVideo",{
          testo:testo,
          titolo:titolo,
          imgs:imgs,
          lingua:document.getElementById("language").value,
          id:utente.nome
        },{headers:{'Content-Type':'multipart/form-data'}}).then((e)=>{
          setCaricamento(false)
          if(e.status===200){
            setTimeout(()=>{
              audio.src=(serverURL+e.data)
              audio.type='video/mp4'
              audio.load()
            },500)
          }else{
            alert("Error: "+e.data)
          }
        })
      }
    }else{
      alert("Audio Error: To obtain this attraction point audio you must be registered <a href='/login' target='_blank'>Login</a>.")
    }
  }*/
  useEffect(()=>{
    const searchParams = new URLSearchParams(window.location.search);
    const lat = searchParams.get("lat");
    const lon = searchParams.get("lon");
    if(lat&&lon){
      setView([parseFloat(lat),parseFloat(lon)])
    }else{
      try {
        axios.get('https://ipinfo.io/json').then((response)=>{
          const data = response.data;
          axios.get(`https://nominatim.openstreetmap.org/reverse?lat=${parseFloat(data.loc.split(',')[0])}&lon=${parseFloat(data.loc.split(',')[1])}&format=json`).then(e=>setCitta(e.data)).catch(err=>alert("Error: "+err))
          //findAttraction(parseFloat(data.loc.split(',')[0]),parseFloat(data.loc.split(',')[1]))
          setView([parseFloat(data.loc.split(',')[0]),parseFloat(data.loc.split(',')[1])])
        });
      } catch(error) {
        setView([45.5357,12.3242])
        alert("Error retrieving location information: "+error);
        return null;
      }
    }
  },[])
  //componente per muovere la div nera e la div bianca dei risultati
  useEffect(()=>{
    //line-info
    if(document.getElementById('line-info')&&document.getElementById('resizableDiv-info')){
      const line = document.getElementById('line-info');
      const resizableDiv = document.getElementById('resizableDiv-info');
      let isDragging = false;
      let startY;
      let lastY;
      line.addEventListener("dblclick", () => {
        setInfo()
        resizableDiv.style.transform="translateY(100%)"
        resizableDiv.style.opacity="0"
      });
      line.addEventListener('mousedown', startDragging);
      line.addEventListener('touchstart', startDragging);
      function startDragging(event) {
        isDragging = true;
        startY = event.clientY||event.touches[0].clientY;
        lastY = startY;
      }
      document.addEventListener('mousemove', drag);
      document.addEventListener('touchmove', drag);
      function drag(event) {
        if (isDragging) {
          let clientY = event.clientY || event.touches[0].clientY;
          let deltaY = clientY - lastY;
          if (deltaY < 0) {
            resizableDiv.style.transform="translateY(0%)"
          } else if (deltaY > 0) {
            setInfo()
            resizableDiv.style.transform="translateY(100%)"
            resizableDiv.style.opacity="0"
          }
          lastY = clientY;
        }
      }
      document.addEventListener('mouseup', stopDragging);
      document.addEventListener('touchend', stopDragging);
      function stopDragging() {
        isDragging = false;
      }
    }
  })
  //calcola distanze e posizione per dirlo nella caregoria "In generale"
  function toRadians(degrees) {
    return degrees * Math.PI / 180;
  }
  function calcolaDistanza(lat1, lon1, lat2, lon2) {
    const R = 6371; // Raggio medio della Terra in chilometri
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distanza in chilometri
    return distance * 1000; // Converti in metri
  }
  const openTextOSummary=(id)=>{
    if(document.getElementById(id).style.height!=="90vh"){
      document.getElementById(id).style.height="90vh"
    }else{
      document.getElementById(id).style.height="100px"
    }
  }
  function ChangeView(){
    const map=useMap();
    useEffect(()=>{
      map.setView(view)
      const provider = new OpenStreetMapProvider();
      const searchControl = new GeoSearchControl({
        position: "topleft",
        provider: provider,
        autoClose: true,
        autoComplete: true,
        autoCompleteDelay: 250,
      });
      map.addControl(searchControl)
      const box = document.querySelector('.geosearch ');
      if (box) {           
        box.style.borderRadius = '50%';
        box.style.border= 'none'         
      }
      const buttonSearch = document.querySelector('.geosearch  a');
      if (buttonSearch) {
        buttonSearch.style.backgroundColor = 'white';              
        buttonSearch.style.borderRadius = '50%'; 
        buttonSearch.style.boxShadow= '0 2px 5px #888'          
      }
      const form = document.querySelector('.geosearch form');
      if (form) {
        form.style.marginLeft = '0px';
        form.style.boxShadow= 'none'  
        form.style.backgroundColor = 'transparent';   
      }
      const formInput = document.querySelector('.geosearch input');
      if (formInput) {
        formInput.style.borderRadius = '20px';
        formInput.style.boxShadow = '0 2px 5px #888';   
      }
      const formButton = document.querySelector('.geosearch button');
      if (formButton) { 
        formButton.style.borderRadius = '0 20px 20px 0';
      }
      const formResult = document.querySelector('.geosearch .results');
      if (formResult) { 
        formResult.style.borderRadius = '20px';
        formResult.style.boxShadow = '0px 2px 5px #888';
        formResult.style.marginTop = '10px';
        formResult.style.border = 'none'; 
      }
      return () => {
        map.removeControl(searchControl);
      };
    },[map])
    useMapEvent("moveend",()=>{
      axios.get(`https://nominatim.openstreetmap.org/reverse?lat=${map.getCenter().lat}&lon=${map.getCenter().lng}&format=json`).then(e=>e.data&&!e.data.error&&setCitta(e.data)).catch(err=>alert("Error: "+err.text))
      setView(map.getCenter())
    })
  }
  const [locateActive, setLocateActive] = useState(false);
  const locateControlRef=useRef(null);
  const LocateControl=()=>{
    const map=useMap();
    useEffect(()=>{
      if(locateControlRef.current){
        return;
      }
      const lc=L.control.locate({
        position:'topleft',
        setView:'once',
        keepCurrentZoomLevel:true,
        strings:{
          title:"I am here",
        },
        locateOptions:{
          enableHighAccuracy:true,
        },
      });
      lc.addTo(map);
      const box = document.querySelector('.leaflet-control-locate');
      if (box) {           
        box.style.borderRadius = '50%';
        box.style.border= 'none'
        box.style.boxShadow= '0 2px 5px #888'           
      }
      const buttonLocate = document.querySelector('.leaflet-control-locate a');
      if (buttonLocate) {
        buttonLocate.style.backgroundColor = 'white';              
        buttonLocate.style.borderRadius = '50%';          
      }
      locateControlRef.current=lc;
    },[map]);
    return null;
  };
  //per dare la possibilità agli utenti di scrivere di una particolare attrazione turistica
  /*const [write,setWrite]=useState(false)
  const submit=(text,file,idwiki)=>{
    setCaricamento(true)
    axios.put(serverURL+"submitToIdWiki",{
      nome:utente.nome,
      email:utente.email,
      password:utente.password,
      text:text,
      file:file,
      idWiki:idwiki
    },{headers:{'Content-Type':'multipart/form-data'}}).then((e)=>{
        setCaricamento(false)
        if(e.status===200){
          window.location.reload()
        }else{
          alert("Login Error: "+e.data)
        }
    })
  }*/
  const MarkerCluster = ({children}) => {
    const map = useMap();
    useEffect(() => {
      const markerClusterGroup = L.markerClusterGroup();
      map.addLayer(markerClusterGroup);
      children&&children.forEach((child,n) => {
        if (child && child.props.position) {
          const marker = L.marker(child.props.position);
          if (child.props.children&&child.props.icon){
            marker.setIcon(child.props.icon);
            marker.bindPopup(child.props.children.props.children[0].props.children+"<br/>"+"<small style='color:#0096FF'>"+child.props.children.props.children[1].props.children+"</small><br/>").addEventListener("click",()=>{
              document.getElementById("n"+n).style.display="block"
              document.getElementById("n"+n).scrollIntoView({behavior:"smooth"})
          })
          }
          markerClusterGroup.addLayer(marker);
        }
      });
      return () => {
        map.removeLayer(markerClusterGroup);
      };
    },[children, map]);
    return null;
  }
  function GetPosition(){
    const map = useMap();
    const handleButtonClick =()=>{
      findAttractionBounds(map.getBounds().getNorthEast().lat,map.getBounds().getNorthEast().lng,map.getBounds().getSouthWest().lat,map.getBounds().getSouthWest().lng)
    };
    return (
      <div className='start-content-map-button'>
        <button onClick={handleButtonClick}>Search Here</button>
      </div>
    );
  }
  function GetLanguage({selectedLanguage, setSelectedLanguage }){
    return (
      <div className='start-content-map-select'>
        <select id="language" value={selectedLanguage} onChange={(event)=>setSelectedLanguage(event.target.value)}>
          <option value="it">Italiano</option>
          <option value="en">English</option>
          <option value="es">Español</option>
          <option value="fr">Français</option>
          <option value="de">Deutsch</option>
          <option value="nl">Nederlands</option>
          <option value="pt">Português</option>
          <option value="ru">Russkiy</option>
          <option value="zh">Zhōngguó rén (Chinese)</option>
          <option value="ja">Nihongo (Japanese)</option>
        </select>
      </div>
    );
  }
  const RouteWithTooltip = () => {
    const map = useMap();
    useEffect(() => {
      const polyline = L.polyline(result.filter(item=>item.tags.tourism==="attraction").map(item=>item.lat?[item.lat,item.lon]:[(item.bounds.maxlat+item.bounds.minlat)/2,(item.bounds.maxlon+item.bounds.minlon)/2]).sort((a,b)=>b[0]-a[0]), { color: 'black', weight: 4 }).addTo(map);
      polyline.bindTooltip("Main tourist attractions route", { permanent: false, direction: "top" });
      return () => {
        map.removeLayer(polyline);
      };
    }, [map]); 
    return null;
  };
  const [visibleCount, setVisibleCount] = useState(10)
  return (
    <div className="start" id='start'>
      {caricamento&&<div className='lds'><div className='lds-c'><div className="lds-spinner"></div><div className='fade-text-container'><p class="fade-text">Loading data from servers...</p><p class="fade-text">Translation of content into the selected language...</p><p class="fade-text">Preparing your personalized user experience...</p><p class="fade-text">Please wait thanks...</p></div></div></div>}
      <div className='start-content'>
        <div className='start-content-map' style={{height: window.innerHeight}}>
          {view?<MapContainer center={view} zoom={16.5} zoomControl={false}>
            <LocateControl activate={locateActive} />
            <TileLayer
              id="mapbox/streets-v11"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              //url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              url="https://maps.geoapify.com/v1/tile/positron/{z}/{x}/{y}.png?apiKey=d85dfbb4d1db4fcc871b071360faf033"
            />
            <ChangeView center={view}/>
            <GetLanguage selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />
            <GetPosition/>
            {result&&<RouteWithTooltip />}
            <MarkerCluster>
              {result&&result.map((item,n)=>{
                if((item.lat&&item.lon)||item.bounds){
                  const coordinates=item.lat?[item.lat,item.lon]:[(item.bounds.maxlat+item.bounds.minlat)/2,(item.bounds.maxlon+item.bounds.minlon)/2]
                  if(item.imageUrl){
                    return(
                      <Marker
                        key={n}
                        position={coordinates}
                        icon={L.divIcon({
                          className: 'custom-icon',
                          html: `<div style="display: flex; flex-direction: column; align-items: center;">
                            <img src="${item.imageUrl}" alt="${item.tags["name:en"]||item.tags["name"]}" style="width: 50px; height: 50px; border-radius: 50%;" />
                            <span style="background-color: white; padding: 2px 5px; border-radius: 5px; margin-top: 2px; font-size: 8px; text-align: center; box-shadow: 0 2px 5px #888">${item.tags["name:en"]||item.tags["name"]}</span>
                          </div>`,
                          iconSize: [50, 50],
                          iconAnchor: [25, 25]
                        })}
                      >
                        <Popup>
                          <div style={{cursor:"pointer"}}>{item.tags["name:en"]||item.tags["name"]}</div>
                          <div style={{color:"var(--red)"}}>{item.tags.tourism||item.tags.historic||item.tags.amenity}</div>
                          <div style={{cursor:"pointer",color:"black"}} onClick={()=>window.open("https://www.google.com/maps/search/?api=1&query="+coordinates[0]+','+coordinates[1])}>Directions</div>
                        </Popup>
                      </Marker>
                    )
                  }else{
                    return(
                      <Marker
                        key={n}
                        position={coordinates}
                        icon={L.icon({
                            iconUrl:"https://cdn-icons-png.flaticon.com/512/25/25613.png",
                            iconSize: [30, 30],
                            iconAnchor: [25, 25]
                        })}
                      >
                        <Popup>
                          <div style={{cursor:"pointer"}} onClick={()=>document.getElementById("n"+n).scrollIntoView({behavior:"smooth"})}>{item.tags["name:en"]||item.tags["name"]}</div>
                          <div style={{color:"var(--red)"}}>{item.tags.tourism||item.tags.historic||item.tags.amenity}</div>
                          <div style={{cursor:"pointer",color:"blue"}} onClick={()=>window.open("https://www.google.com/maps/search/?api=1&query="+coordinates[0]+','+coordinates[1])}>Directions</div>
                        </Popup>
                      </Marker>
                    )
                  }
                }
              })}
            </MarkerCluster>
            <ScaleControl position="bottomright"/>
          </MapContainer>:<div style={{textAlign:"center",marginTop:"35%"}}>
            Loading...
          </div>}
        </div>
        <div id='resizableDiv' className='start-content-results'>
          {citta&&<div className='start-content-results-item' style={{background:"white"}}>
            <div className='start-content-results-item-svg'>
              <svg viewBox="0 0 16 16" onClick={()=>citta&&setView([citta.boundingbox[0],citta.boundingbox[2]])}>
                <path d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022M6 8.694 1 10.36V15h5zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5z"/>
                <path d="M2 11h1v1H2zm2 0h1v1H4zm-2 2h1v1H2zm2 0h1v1H4zm4-4h1v1H8zm2 0h1v1h-1zm-2 2h1v1H8zm2 0h1v1h-1zm2-2h1v1h-1zm0 2h1v1h-1zM8 7h1v1H8zm2 0h1v1h-1zm2 0h1v1h-1zM8 5h1v1H8zm2 0h1v1h-1zm2 0h1v1h-1zm0-2h1v1h-1z"/>
              </svg>
            </div>
            <div className='start-content-results-item-text' onClick={()=>wikiText(citta.address.village||citta.address.city||citta.address.town)} style={{height:"90%",cursor:"pointer"}}>
              <div className='start-content-results-item-title'>{citta.address.village||citta.address.city||citta.address.town}</div>
              <small>city</small>
            </div>
          </div>}
          {result?result.map((item,n)=>{
            let x2=0
            let y2=0
            if(item.lat&&item.lon){
              x2=item.lat
              y2=item.lon
            }else if(item.bounds){
              x2=(item.bounds.maxlat+item.bounds.minlat)/2
              y2=(item.bounds.maxlon+item.bounds.minlon)/2
            }
            return(
              <div id={'n'+n} key={n} className='start-content-results-item' style={n>=10?{display:"none"}:null}>
                <div className='start-content-results-item-img' onClick={()=>wikiText(item.tags.name,item.tags.wikipedia,item.tags.wikidata,x2,y2,item.id,item.imageUrl?item.imageUrl:loading)} style={item.imageUrl?{backgroundImage:"url("+item.imageUrl+")"}:{backgroundImage:"url("+loading+")",backgroundSize:"contain"}}></div>
                <div className='start-content-results-item-info'>
                  <div className='start-content-results-item-text' onClick={()=>setView([x2,y2])}>
                    <div className='start-content-results-item-title'>{item.tags["name:en"]||item.tags["name"]}</div>
                    <small>{item.tags.tourism||item.tags.historic||item.tags.amenity}</small>
                  </div>
                </div>
              </div>
            )
          }):!result&&""}
          {result&&visibleCount<result.length&&<div className='start-content-results-showMore' onClick={()=>{
            setVisibleCount(visibleCount+10)
            for(let i=visibleCount;i<visibleCount+10;i++){
              if(document.getElementById('n'+i))document.getElementById('n'+i).style.display="block"
            }
          }}>
            <p>Show More</p>
            <svg viewBox="0 0 16 16">
              <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1"/>
            </svg>
          </div>}
        </div>
        <div className='start-content-info' id='resizableDiv-info'>
          <div className='line' id='line-info'>
            <div className='line-line'><br/></div>
          </div>
          {info&&citta&&(citta.address.village||citta.address.city||citta.address.town)===info.nome?<div className='start-content-info-content' id='resizableDiv-info-content' style={{backgroundImage:"url("+loading+")",backgroundPosition:"center",backgroundSize:"contain"}}>
            <div className='start-content-info-content-content'>
              <div className='start-content-info-content-content-title'>
                <h2>{info.nome}</h2>
                <div className='start-content-info-content-content-title-rating'>
                  <svg viewBox="0 0 16 16">
                    <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z"/>
                  </svg>
                  <big>{(Math.random() * (5 - 3.5) + 3.5).toFixed(1)}</big>
                </div>
              </div>
              {info.info[0]&&info.info[0].img&&info.info[0].img.length>0&&<div className='start-content-info-content-content-img'>
                {info.info[0].img.map((i,n)=>{
                  return(
                    <img key={n} src={i} alt={"img-"+info.nome} />
                  )
                })}
              </div>}
              <br />
              <div>
                <audio controls id='audio'></audio>
              </div>
              <br />
              <big>Summary</big>
              <div className='start-content-info-content-summary' id='summary' onClick={()=>openTextOSummary("summary")}>
                <div>{info.info&&info.info.map(i=>i.riassunto+" ").join(' ')}</div>
              </div>
              <br />
              <big>Text</big>
              <div className='start-content-info-content-text' id='text' onClick={()=>openTextOSummary("text")}>
                <div>{info.info.map(i=>i.testo+" ").join(' ')}</div>
              </div>
              <br />
              <big>Audio</big>
              <div className='start-content-info-content-list'>
                <div className='start-content-info-content-list-item' onClick={()=>leggiTesto(info.info.map(i=>i.riassunto).join(' '),"Summary")}>
                  <div className='start-content-info-content-list-item-svg'>
                    <svg viewBox="0 0 16 16">
                      <path d="M8.5 2a.5.5 0 0 1 .5.5v11a.5.5 0 0 1-1 0v-11a.5.5 0 0 1 .5-.5m-2 2a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5m4 0a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5m-6 1.5A.5.5 0 0 1 5 6v4a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m8 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m-10 1A.5.5 0 0 1 3 7v2a.5.5 0 0 1-1 0V7a.5.5 0 0 1 .5-.5m12 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0V7a.5.5 0 0 1 .5-.5"/>
                    </svg>
                  </div>
                  <div>Summary</div>
                </div>
                {info&&info.info.map((i,n)=>{
                  return(
                    <div key={n} className='start-content-info-content-list-item' onClick={()=>leggiTesto(i.testo,i.titolo)}>
                      <div className='start-content-info-content-list-item-svg'>
                        <svg viewBox="0 0 16 16">
                          <path d="M8.5 2a.5.5 0 0 1 .5.5v11a.5.5 0 0 1-1 0v-11a.5.5 0 0 1 .5-.5m-2 2a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5m4 0a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5m-6 1.5A.5.5 0 0 1 5 6v4a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m8 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m-10 1A.5.5 0 0 1 3 7v2a.5.5 0 0 1-1 0V7a.5.5 0 0 1 .5-.5m12 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0V7a.5.5 0 0 1 .5-.5"/>
                        </svg>
                      </div>
                      <div>{i.titolo}</div>
                    </div>
                  )
                })}
              </div>
              <small><a href={"https://it.wikipedia.org/wiki/"+info.nome} target="_blank" rel="noreferrer">By Wikipedia</a></small>
              <br />
              <br />
            </div>
          </div>:info&&result&&result.filter(i=>i.tags.name===info.nome).length>0&&<div className='start-content-info-content' id='resizableDiv-info-content' style={result.filter(i=>i.tags.name===info.nome)[0].imageUrl?{backgroundImage:"url("+result.filter(i=>i.tags.name===info.nome)[0].imageUrl+")",backgroundPosition:"center",backgroundSize:"cover"}:{backgroundImage:"url("+loading+")",backgroundPosition:"center",backgroundSize:"contain"}}>
            <div className='start-content-info-content-content'>
              <div className='start-content-info-content-content-title'>
                <h2>{info.nome}</h2>
                <div className='start-content-info-content-content-title-rating'>
                  <svg viewBox="0 0 16 16">
                    <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z"/>
                  </svg>
                  <big>{(Math.random() * (5 - 3.5) + 3.5).toFixed(1)}</big>
                </div>
              </div>
              {info.info[0]&&info.info[0].img&&info.info[0].img.length>0&&<div className='start-content-info-content-content-img'>
                {info.info[0].img.map((i,n)=>{
                  return(
                    <img key={n} src={i} alt={"img-"+info.nome} />
                  )
                })}
              </div>}
              <br />
              <div>
                <audio controls id='audio'></audio>
              </div>
              <div className='start-content-info-content-grid'>
                {result.filter(i=>i.tags.name===info.nome)[0].bounds?<div><small>Go to: </small><a href={"https://www.google.com/maps/place/"+result.filter(i=>i.tags.name===info.nome)[0].bounds.maxlat+","+result.filter(i=>i.tags.name===info.nome)[0].bounds.maxlon} target='__blank'>directions</a></div>:<div><small>Go to: </small><a href={"https://www.google.com/maps/place/"+result.filter(i=>i.tags.name===info.nome)[0].lat+","+result.filter(i=>i.tags.name===info.nome)[0].lon} target='__blank'>directions</a></div>}
                {Object.keys(result.filter(i=>i.tags.name===info.nome)[0].tags).map((i,n)=>{
                  if(Object.keys(result.filter(i=>i.tags.name===info.nome)[0].tags)[n]==="website"){
                    return(
                      <div key={n}><small>{Object.keys(result.filter(i=>i.tags.name===info.nome)[0].tags)[n]}</small>: <a href={result.filter(i=>i.tags.name===info.nome)[0].tags[i]} target='_blank' rel="noreferrer">{result.filter(i=>i.tags.name===info.nome)[0].tags[i]}</a></div>
                    )
                  }else if(Object.keys(result.filter(i=>i.tags.name===info.nome)[0].tags)[n]==="phone"){
                    return(
                      <div key={n}><small>{Object.keys(result.filter(i=>i.tags.name===info.nome)[0].tags)[n]}</small>: <a href={"tel:"+result.filter(i=>i.tags.name===info.nome)[0].tags[i]} target='_blank' rel="noreferrer">{result.filter(i=>i.tags.name===info.nome)[0].tags[i]}</a></div>
                    )
                  }
                })}
              </div>
              <br />
              <big>Summary</big>
              <div className='start-content-info-content-summary' id='summary' onClick={()=>openTextOSummary("summary")}>
                <div>{info.info&&info.info.map(i=>i.riassunto+" ").join(' ')}</div>
              </div>
              <br />
              <big>Text</big>
              <div className='start-content-info-content-text' id='text' onClick={()=>openTextOSummary("text")}>
                <div>{info.info.map(i=>i.testo+" ").join(' ')}</div>
              </div>
              <br />
              <big>Audio</big>
              <div className='start-content-info-content-list'>
                <div className='start-content-info-content-list-item' onClick={()=>leggiTesto(info.info.map(i=>i.riassunto).join(' '),"Summary")}>
                  <div className='start-content-info-content-list-item-svg'>
                    <svg viewBox="0 0 16 16">
                      <path d="M8.5 2a.5.5 0 0 1 .5.5v11a.5.5 0 0 1-1 0v-11a.5.5 0 0 1 .5-.5m-2 2a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5m4 0a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5m-6 1.5A.5.5 0 0 1 5 6v4a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m8 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m-10 1A.5.5 0 0 1 3 7v2a.5.5 0 0 1-1 0V7a.5.5 0 0 1 .5-.5m12 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0V7a.5.5 0 0 1 .5-.5"/>
                    </svg>
                  </div>
                  <div>Summary</div>
                </div>
                {info&&info.info.map((i,n)=>{
                  return(
                    <div key={n} className='start-content-info-content-list-item' onClick={()=>leggiTesto(i.testo,i.titolo)}>
                      <div className='start-content-info-content-list-item-svg'>
                        <svg viewBox="0 0 16 16">
                          <path d="M8.5 2a.5.5 0 0 1 .5.5v11a.5.5 0 0 1-1 0v-11a.5.5 0 0 1 .5-.5m-2 2a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5m4 0a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5m-6 1.5A.5.5 0 0 1 5 6v4a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m8 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m-10 1A.5.5 0 0 1 3 7v2a.5.5 0 0 1-1 0V7a.5.5 0 0 1 .5-.5m12 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0V7a.5.5 0 0 1 .5-.5"/>
                        </svg>
                      </div>
                      <div>{i.titolo}</div>
                    </div>
                  )
                })}
              </div>
              <small><a href={"https://it.wikipedia.org/wiki/"+info.nome} target="_blank" rel="noreferrer">By Wikipedia</a></small>
              <br />
              <br />
            </div>
          </div>}
        </div>
      </div>
    </div>
  );
}

export default Explore