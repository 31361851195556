import React, { useEffect, useState } from "react";
import {BrowserRouter,Route,Routes} from 'react-router-dom';
import axios from "axios"
import Home from "./components/home/Home"
import Explore from "./components/explore/Explore";
import Navbar from "./components/navbar/Navbar";
import Why from "./components/why/Why"
import About from "./components/about/About";
import Contacts from "./components/contacts/Contacts";
import Login from "./components/login/Login";
import Utente from "./components/utente/Utente";
import Footer from "./components/footer/Footer";
import PageError from "./components/pageError/PageError";

function App(){
  const [utente,setUtente]=useState()
  const serverURL="https://cultura-server.onrender.com/"
  //const serverURL="http://localhost:3001/"
  const stayLogin=()=>{
    if(document.cookie.split(";").find(i=>i.includes("email"))&&document.cookie.split(";").find(i=>i.includes("password"))){
      axios.put(serverURL+"login",{
        email:document.cookie.split(";").find(i=>i.includes("email")).trim().split("=")[1],
        password:document.cookie.split(";").find(i=>i.includes("password")).trim().split("=")[1],
      },{headers:{'Content-Type':'multipart/form-data'}}).then((e)=>{
        if(e.status===200){
          setUtente(e.data)
        }else{
          alert("Error: You are not logged in")
          setUtente(203)
        }
      })
    }
  }
  const handleError = (error) => {
    alert("Error: "+error).then(window.location.reload())
  };
  useEffect(()=>{
    stayLogin()
    window.addEventListener('error', handleError);
    window.addEventListener('unhandledrejection', handleError);
  },[])
  return (
    <BrowserRouter>
      <Navbar utente={utente}/>
      <Routes>
      <Route path='/' element={<Home serverURL={serverURL}/>}/>
        <Route path='/why' element={<Why serverURL={serverURL}/>}/>
        <Route path='/who' element={<About/>}/>
        <Route path='/contacts' element={<Contacts serverURL={serverURL}/>}/>
        <Route path='/explore' element={<Explore serverURL={serverURL} utente={utente}/>}/>
        <Route path='/login' element={<Login serverURL={serverURL}/>}/>
        <Route path='/utente' element={<Utente serverURL={serverURL} utente={utente}/>}/>
        <Route path='/*' element={<PageError />}/>
      </Routes>
      {/*!window.matchMedia('(display-mode:standalone)').matches&&*/!window.location.pathname.includes("explore")&&<Footer/>}
    </BrowserRouter>
  );
}

export default App;
