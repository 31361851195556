import React,{useState} from 'react'
import './home.css'
import img from './home.jpg'

const Home = () => {
  const [c,setC]=useState(5)
  const [d,setD]=useState(5)
  const cities=[
    { 
      rank: 10, 
      city: "Istanbul", 
      country: "Turkey", 
      latitude: 41.0082, 
      longitude: 28.9784, 
      image: "https://images.pexels.com/photos/3889704/pexels-photo-3889704.jpeg?auto=compress&cs=tinysrgb&w=600" 
    },
    { 
      rank: 9, 
      city: "Barcelona", 
      country: "Spain", 
      latitude: 41.3851, 
      longitude: 2.1734, 
      image: "https://images.pexels.com/photos/1874675/pexels-photo-1874675.jpeg?auto=compress&cs=tinysrgb&w=600"
    },
    { 
      rank: 8, 
      city: "Singapore", 
      country: "Singapore", 
      latitude: 1.3521, 
      longitude: 103.8198, 
      image: "https://images.pexels.com/photos/326699/pexels-photo-326699.jpeg?auto=compress&cs=tinysrgb&w=600" 
    },
    { 
      rank: 7, 
      city: "Bangkok", 
      country: "Thailand", 
      latitude: 13.7563, 
      longitude: 100.5018, 
      image: "https://images.pexels.com/photos/1031659/pexels-photo-1031659.jpeg?auto=compress&cs=tinysrgb&w=600" 
    },
    {
      rank: 6,
      city: "Dubai",
      country: "Arab Emirates",
      latitude: 25.276987,
      longitude: 55.296249,
      image: "https://images.pexels.com/photos/1467300/pexels-photo-1467300.jpeg?auto=compress&cs=tinysrgb&w=600"
    },
    {
      rank: 5,
      city: "London",
      country: "UK",
      latitude: 51.507341,
      longitude: -0.127497,
      image: "https://images.pexels.com/photos/635608/pexels-photo-635608.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 4,
      city: "Tokyo",
      country: "Japan",
      latitude: 35.680578,
      longitude: 139.768496,
      image: "https://images.pexels.com/photos/2506923/pexels-photo-2506923.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 3,
      city: "Rome",
      country: "Italy",
      latitude: 41.896562,
      longitude: 12.482170,
      image: "https://images.pexels.com/photos/2422251/pexels-photo-2422251.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 2,
      city: "Paris",
      country: "France",
      latitude: 48.857450,
      longitude: 2.351338,
      image: "https://images.pexels.com/photos/3105066/pexels-photo-3105066.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 1,
      city: "New York",
      country: "USA",
      latitude: 40.712376,
      longitude: -74.006040,
      image: "https://images.pexels.com/photos/1389339/pexels-photo-1389339.jpeg?auto=compress&cs=tinysrgb&w=800"
    }
  ];
  const attractions = [
    {
      rank: 10,
      name: "Sydney Opera House",
      city: "Sydney",
      country: "Australia",
      latitude: -33.856784,
      longitude: 151.215297,
      image: "https://images.pexels.com/photos/5326974/pexels-photo-5326974.jpeg?auto=compress&cs=tinysrgb&w=300"
    },
    {
      rank: 9,
      name: "Machu Picchu",
      city: "Aguas Calientes",
      country: "Peru",
      latitude: -13.163068,
      longitude: -72.545128,
      image: "https://images.pexels.com/photos/2929906/pexels-photo-2929906.jpeg?auto=compress&cs=tinysrgb&w=300"
    },
    {
      rank: 8,
      name: "Great Wall of China",
      city: "Beijing",
      country: "China",
      latitude: 40.431908,
      longitude: 116.570374,
      image: "https://images.pexels.com/photos/1423580/pexels-photo-1423580.jpeg?auto=compress&cs=tinysrgb&w=600"
    },
    {
      rank: 7,
      name: "Statue of Liberty",
      city: "New York",
      country: "USA",
      latitude: 40.688354,
      longitude: -74.043692,
      image: "https://images.pexels.com/photos/356844/pexels-photo-356844.jpeg?auto=compress&cs=tinysrgb&w=600"
    },
    {
      rank: 6,
      name: "Parthenon",
      city: "Athens",
      country: "Greece",
      latitude: 37.971101,
      longitude: 23.725673,
      image: "https://images.pexels.com/photos/20872396/pexels-photo-20872396/free-photo-of-citta-punto-di-riferimento-notte-grecia.png?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 5,
      name: "Colosseum",
      city: "Rome",
      country: "Italy",
      latitude: 41.889860,
      longitude: 12.493543,
      image: "https://images.pexels.com/photos/5360481/pexels-photo-5360481.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 4,
      name: "Lincoln Memorial",
      city: "Washington D.C.",
      country: "USA",
      latitude: 38.889210,
      longitude: -77.049570,
      image: "https://images.pexels.com/photos/5652177/pexels-photo-5652177.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 3,
      name: "Palace of Versailles",
      city: "Versailles",
      country: "France",
      latitude: 48.804966,
      longitude: 2.119677,
      image: "https://images.pexels.com/photos/17283207/pexels-photo-17283207/free-photo-of-francia-punto-di-riferimento-viaggio-turisti.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 2,
      name: "Eiffel Tower",
      city: "Paris",
      country: "France",
      latitude: 48.858844,
      longitude: 2.294351,
      image: "https://images.pexels.com/photos/532826/pexels-photo-532826.jpeg?auto=compress&cs=tinysrgb&w=600"
    },
    {
      rank: 1,
      name: "Forbidden City",
      city: "Beijing",
      country: "China",
      latitude: 39.916606,
      longitude: 116.397141,
      image: "https://images.pexels.com/photos/5106090/pexels-photo-5106090.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    }
  ];

  return (
    <div className='home'>
      <div className='home-title' style={{backgroundImage:"url("+img+")"}}>
        <div className='home-title-content'>
          <br />
          <h1>Best free online travel guide</h1>
          <small>Destination World</small>
          <br />
          <a href="/explore">Explore Now!</a>
        </div>
      </div>
      <div className='home-content'>
        <div className='home-content-types'>
          <div className='home-content-types-item'>
            <p>Art</p>
          </div>
          <div className='home-content-types-item'>
            <p>Archaeological sites</p>
          </div>
          <div className='home-content-types-item'>
            <p>Attractions</p>
          </div>
          <div className='home-content-types-item'>
            <p>Castles</p>
          </div>
          <div className='home-content-types-item'>
            <p>Museums</p>
          </div>
          <div className='home-content-types-item'>
            <p>Religious Places</p>
          </div>
          <div className='home-content-types-item'>
            <p>Ruins</p>
          </div>
        </div>
        <div className='home-content-subtitle'>
          <h3>Cronology</h3>
          <svg viewBox="0 0 16 16" onClick={()=>window.confirm("Do you want delete your cronology?")&&localStorage.removeItem("cronology")}>
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
          </svg>
        </div>
        <div className='home-content-popular'>
          {localStorage.getItem("cronology")?JSON.parse(localStorage.getItem("cronology")).reverse().map((item,key)=>{
            return(
              <a href={"/explore?lat="+item.lat+"&&lon="+item.lon}>
                <div className='home-content-popular-item' style={{backgroundImage:"url("+item.img+")"}}>
                  <svg viewBox="0 0 16 16" style={{fill:"white",background:"black"}}>
                    <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022zm2.004.45a7 7 0 0 0-.985-.299l.219-.976q.576.129 1.126.342zm1.37.71a7 7 0 0 0-.439-.27l.493-.87a8 8 0 0 1 .979.654l-.615.789a7 7 0 0 0-.418-.302zm1.834 1.79a7 7 0 0 0-.653-.796l.724-.69q.406.429.747.91zm.744 1.352a7 7 0 0 0-.214-.468l.893-.45a8 8 0 0 1 .45 1.088l-.95.313a7 7 0 0 0-.179-.483m.53 2.507a7 7 0 0 0-.1-1.025l.985-.17q.1.58.116 1.17zm-.131 1.538q.05-.254.081-.51l.993.123a8 8 0 0 1-.23 1.155l-.964-.267q.069-.247.12-.501m-.952 2.379q.276-.436.486-.908l.914.405q-.24.54-.555 1.038zm-.964 1.205q.183-.183.35-.378l.758.653a8 8 0 0 1-.401.432z"/>
                    <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0z"/>
                    <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5"/>
                  </svg>
                  <p>{item.nome}</p>
                  <big>{new Date(item.data).getDate()}/{new Date(item.data).getMonth()}/{new Date(item.data).getFullYear()} {new Date(item.data).getHours()}:{new Date(item.data).getMinutes()}</big>
                </div>
              </a>
            )
          }):"No data"}
        </div>
        <div className='home-content-subtitle'>
          <h3>Popular Destinations</h3>
          <small onClick={()=>setC(10)}>See All</small>
        </div>
        <div className='home-content-popular'>
          {cities.slice(0,c).map(city=>{
            return(
              <a href={"/explore?lat="+city.latitude+"&&lon="+city.longitude}>
                <div className='home-content-popular-item' style={{backgroundImage:"url("+city.image+")"}}>
                  <small>{city.rank}</small>
                  <svg viewBox="0 0 16 16">
                    <path d="M9.669.864 8 0 6.331.864l-1.858.282-.842 1.68-1.337 1.32L2.6 6l-.306 1.854 1.337 1.32.842 1.68 1.858.282L8 12l1.669-.864 1.858-.282.842-1.68 1.337-1.32L13.4 6l.306-1.854-1.337-1.32-.842-1.68zm1.196 1.193.684 1.365 1.086 1.072L12.387 6l.248 1.506-1.086 1.072-.684 1.365-1.51.229L8 10.874l-1.355-.702-1.51-.229-.684-1.365-1.086-1.072L3.614 6l-.25-1.506 1.087-1.072.684-1.365 1.51-.229L8 1.126l1.356.702z"/>
                    <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1z"/>
                  </svg>
                  <p>{city.city}</p>
                  <big>{city.country}</big>
                </div>
              </a>
            )
          })}
        </div>
        <div className='home-content-subtitle'>
          <h3>Popular sites</h3>
          <small onClick={()=>setD(10)}>See All</small>
        </div>
        <div className='home-content-popular'>
          {attractions.slice(0,d).map(attraction=>{
            return(
              <a href={"/explore?lat="+attraction.latitude+"&&lon="+attraction.longitude}>
                <div className='home-content-popular-item' style={{backgroundImage:"url("+attraction.image+")"}}>
                  <small>{attraction.rank}</small>
                  <svg viewBox="0 0 16 16">
                    <path d="M9.669.864 8 0 6.331.864l-1.858.282-.842 1.68-1.337 1.32L2.6 6l-.306 1.854 1.337 1.32.842 1.68 1.858.282L8 12l1.669-.864 1.858-.282.842-1.68 1.337-1.32L13.4 6l.306-1.854-1.337-1.32-.842-1.68zm1.196 1.193.684 1.365 1.086 1.072L12.387 6l.248 1.506-1.086 1.072-.684 1.365-1.51.229L8 10.874l-1.355-.702-1.51-.229-.684-1.365-1.086-1.072L3.614 6l-.25-1.506 1.087-1.072.684-1.365 1.51-.229L8 1.126l1.356.702z"/>
                    <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1z"/>
                  </svg>
                  <p>{attraction.name}</p>
                  <big>{attraction.city}, {attraction.country}</big>
                </div>
              </a>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Home