import React,{useState} from 'react'
import "./contacts.css"
import axios from 'axios'

const Contacts = ({serverURL}) => {
  document.title="Contacts | Journey"

  const sendEmail=async()=>{
    try{
        await axios.put(serverURL+"sendEmail",{
            email:document.getElementById("email").value,
            oggetto:document.getElementById("oggetto").value,
            testo:document.getElementById("testo").value,
        },{headers:{'Content-Type':'multipart/form-data'}}).then(e=>{
            if(e.status!==200){
              alert("Contact Error: "+e.data)
            }else{
              alert("Request Sent: Request sent successfully")
            }
        })
    }catch(err){
      alert("Error: "+err)
    }
  }
  return (
    <div className='contacts'>
      <div className='contacts-title'>
        <div>Contact us.</div>
      </div>
      <div className='contacts-content'>
        <form className='contacts-content-form'>
          <div className='contacts-content-form-item'>
            <div>Email.</div>
            <input id='email' type="text" required/>
          </div>
          <div className='contacts-content-form-item'>
            <div>Object.</div>
            <input id='oggetto' type="text" required/>
          </div>
          <div className='contacts-content-form-item'>
            <div>Text.</div>
            <textarea id="testo" cols="30" rows="10" required></textarea>
          </div>
          <div className='contacts-content-form-item'>
            <button onClick={()=>sendEmail()}>Send</button>
          </div>
        </form>
        <div className='contacts-content-img'>
          <img src="https://images.pexels.com/photos/225232/pexels-photo-225232.jpeg?auto=compress&cs=tinysrgb&w=800" alt="contacts_us" />
        </div>
      </div>
    </div>
  )
}

export default Contacts