import React from 'react'
import "./navbar.css"
import logo from "../../logo.jpg"

const Navbar = ({utente}) => {
  return (
    <nav className='navbar' id='navbar'>
      <div className='navbar-menu'>
        <a href='/' className='navbar-item'>
          <svg viewBox="0 0 16 16">
            <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5z"/>
          </svg>
        </a>
        <a href="/explore" className='navbar-item'>
          <svg viewBox="0 0 16 16">
            <path d="M8 16.016a7.5 7.5 0 0 0 1.962-14.74A1 1 0 0 0 9 0H7a1 1 0 0 0-.962 1.276A7.5 7.5 0 0 0 8 16.016m6.5-7.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0"/>
            <path d="m6.94 7.44 4.95-2.83-2.83 4.95-4.949 2.83 2.828-4.95z"/>
          </svg>
        </a>
        <div><img src={logo}/></div>
        <a className='navbar-item' href="/utente">
          <svg viewBox="0 0 16 16">
            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
            <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
          </svg>
        </a>
        <div className='navbar-item' onClick={()=>document.getElementById("navbar-list").style.transform="translateX(0%)"}>
          <svg viewBox="0 0 16 16">
            <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3"/>
          </svg>
        </div>
      </div>
      <div className='navbar-list' id='navbar-list'>
        <div className='navbar-list-exit' onClick={()=>document.getElementById("navbar-list").style.transform="translateX(-101%)"}>
          <svg viewBox="0 0 16 16" style={{fill:'var(--red)'}}>
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
          </svg>
        </div>
        <div className='navbar-list-access' onClick={()=>window.location.href="/"}>
          <a href="/login" style={{color:"black"}}>Login</a>
          <a href="/login?signup=true" style={{color:"white",background:"black"}}>Register</a>
        </div>
        <div className='navbar-list-item'>
          <a href='/'>Home</a>
        </div>
        <div className='navbar-list-item'>
          <a href='/explore'>Explore</a>
        </div>
        <div className='navbar-list-item'>
          <a href='/contacts'>Contacts</a>
        </div>
        <div className='navbar-list-item'>
          <a href='/utente'>User</a>
        </div>
      </div>
    </nav>
  )
}

export default Navbar